@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
 h1, h2, h3, h4, h5, h6,
 p, blockquote, pre,
 ul, ol, dl, figure,
 %vertical-rhythm {
   margin-bottom: $base-font-size;
 }
 
html {
  font-family: $base-font-family;
  color: $text-color;
  font-size: 16px;
  line-height: 1.5;
}

body {
  background: $grey-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
     -moz-font-smoothing: antialiased !important;
  
     text-rendering: optimizelegibility;
}

::selection {
  background: $main-color;
  text-shadow: none;
}

audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

h1 {
  font-family: 'Montserrat', $base-font-family;
  margin: 0;
  padding:0 0 0.25em;
  font-size: 5em;
  font-weight: normal;
  text-align: center;
}

a h1 {
  color:#FFF;
  text-decoration:inherit;
}

h2 {
  font-family: 'Montserrat', $base-font-family;
  font-size: 1.6667em;
  line-height: 1.4em;
  margin-top: 0.9em;
  margin-bottom: 0.9em;
}

a {
  color: #0080C6;
  text-decoration: none;
}

p {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

pre, code {
  font-family: Consolas, monaco, monospace;
  font-size: 0.9em;
  padding:3px;
}

/* ========================================================================== */

.navigation {
  list-style: none;
  padding: 0;
  margin: 10px 0 60px;
  text-align: center;

  & > li {
    line-height:24px;
    padding: 0 25px 0;
    display: inline-block;

    a {
        color: $grey-color-lightest;
        text-decoration: none;
        &:hover {
            color: $text-color;
        }
    }
  }

  .svg-icon {
    width: 1.5em;
    height: 1.5em;
    fill: $main-color;
    transition: 0.3s fill;
    &:hover {
      fill: $main-color-lighter;
    }
  }
}

.content {
  max-width: $content-width;
  margin: auto;
  padding: 0 24px;
}

.footer {
  text-align: right;
  padding: 20px;
}

.intro {
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.entry {
  margin: 0px 0px 1.5em;
  background: $grey-color-light;
  overflow: hidden;
}
.entry-title {
  padding: 20px 25px 10px;
  margin: 0;
  a {
    color:inherit;
    text-decoration: inherit;
  }
}
.entry-content {
  padding: 0 25px 0;
}

.entry-date {
  padding: 0 25px 20px;
  text-align: right;
  font-size: 0.9em;
  font-weight: 500;
}

.post {
  img, video, iframe {
    max-width: 100%;
  }
}
.post-header {
  font-size: 32px;
  margin:40px 0;
}
.post-date {
  text-align: right;
  font-size:0.9em;
  position: relative;
  font-weight: 500;
  height: 0;
  padding-top:10px;
}

.center-image {
  display: block;
  margin: 15px auto 25px;
}

.highlight {
  margin: 1em 0;
}

.main-image {
  margin: 2em auto 0.5em;
  width: 264px;
  border-radius: 50%;
  display: block;
}