@charset "utf-8";

// Variables
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
//$base-font-family: Helvetica, Arial, sans-serif;
//$base-font-family: Consolas, "Times New Roman";
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$text-color:       #FFFFFF;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       rgb(32,37,41);
$grey-color-light: rgb(38,43,47);
$grey-color-dark:  rgb(32,37,41);
$grey-color-lightest: rgb(158,151,153);

$on-palm:          600px;
$on-laptop:        800px;

$main-color:        rgb(255,186,0);
$main-color-lighter:rgb(255, 211, 89);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "normalize",
    "base",
    "syntax-highlighting"
;
